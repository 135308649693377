import {
  create,
  get,
  supported,
  PublicKeyCredentialDescriptorJSON,
  PublicKeyCredentialWithAttestationJSON,
} from '@github/webauthn-json';
import appService from '../services/endpoints';

type Registration = {
  companySecId: string | undefined;
  registration: PublicKeyCredentialWithAttestationJSON;
}

export const WEBAUTHN_REGISTRATIONS = 'pagochat-webauthn-registrations';

export const supports = () => {
  const windowGlobal: any = typeof window !== 'undefined' && window;
  return new Promise<void>((resolve, reject) => {
    if (supported()) {
      console.log('PublicKeyCredential API available. Check if user-verifying platform authenticator is available...');
      // we are specifically looking for Android or iOS authenticators
      // see: https://developer.mozilla.org/en-US/docs/Web/API/PublicKeyCredential/isUserVerifyingPlatformAuthenticatorAvailable
      windowGlobal.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        .then((uvpaa: Boolean) => {
          if (uvpaa) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(() => reject());
    } else {
      reject();
    }
  });
};

export const getRegistrations = (): Registration[] =>
  JSON.parse(localStorage.getItem(WEBAUTHN_REGISTRATIONS) || '[]');

const getRegisteredCredentials = (registrations: Registration[]): PublicKeyCredentialDescriptorJSON[] => 
  registrations.map(reg => ({
    id: reg.registration.rawId,
    type: reg.registration.type,
  }));

export const hasRegisteredCredentials = (companySecId: string | undefined): Boolean => {
  const allRegistrations = getRegistrations();
  return allRegistrations.some(registrations => registrations.companySecId === companySecId);
}

const setRegistrations = (registrations: Registration[]): void =>
  localStorage.setItem(WEBAUTHN_REGISTRATIONS, JSON.stringify(registrations, null, '  '));

const saveRegistration = (registration: Registration): void => {
  const registrations = getRegistrations();
  registrations.push(registration);
  setRegistrations(registrations);
};

export const removeRegistrationsByCompanyId = (companySecId: string | undefined): void => {
  const registrations = getRegistrations();
  const filteredRegistrations = registrations.filter(registration => registration.companySecId !== companySecId);
  setRegistrations(filteredRegistrations);
}

export const register = (token: string, companySecId: string | undefined) =>
  appService.postWebAuthnRegistrationStart(token).then((data: any) => {
    // Note: we are purposedly NOT excluding credentials at the time of registering
    // new ones, because, since the same domain can support multiple companies/accounts,
    // the relying party that registers credentials within the authenticator
    // will also be the same; otherwise, the API will throw an error.
    return create({
      publicKey: data,
    }).then(credential => {
      console.log('PublicKeyCredential credential object', credential);
      saveRegistration({
        companySecId,
        registration: credential
      });
      return appService.postWebAuthnRegistrationFinish(token, credential);
    });
  });

export const auth = (token: string) =>
  appService.postWebAuthnAuthenticationStart(token).then((data: any) => {
    data.allowCredentials = getRegisteredCredentials(getRegistrations());
    return get({
      publicKey: data,
    }).then(assertion => {
      console.log('PublicKeyCredential assertion object', assertion);
      return appService.postWebAuthnAuthenticationFinish(token, assertion);
    });
  });